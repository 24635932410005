.navbar-brand {
	color: #02c0a0 !important;
}
.nav-theme {
	background-color: #f1f1f1;
	font-size: 20px;
}

.animate-navbar {
	box-shadow: 1px 1px 1px rgba(206, 204, 204, 0.753);
	animation: moveDown 0.5s ease-in-out;
}

@keyframes moveDown {
	from {
		transform: translateY(-5rem);
	}
	to {
		transform: translateY(0rem);
	}
}
