hr {
	border: 0;
	height: 1px;
	background-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 0),
		rgba(0, 0, 0, 0.75),
		rgba(0, 0, 0, 0)
	);
}

.container-box {
	width: 80%;
	background-color: transparent;
	position: relative;
	z-index: 9;
}

// /* Small devices (portrait tablets and large phones, 600px and up) */
