.jumbotron {
	background-color: transparent;
}
h1 {
	text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.226);
}

// h1 {
// 	color: #221e41 !important;
// 	text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.192);
// }
.color {
	color: #221e41 !important;
}
@media only screen and (max-width: 600px) {
	.font-details-b {
		font-size: 2rem;
	}
}
