#skills {
	padding: 5rem 0 5rem 0 !important;
	margin-top: -10px;
}
.color {
	color: #221e41 !important;
}

.card-title {
	font-size: 2rem;
	font-weight: 600;
}

.image-style {
	width: 40%;
}
.row {
	display: flex;
}
.column {
	flex: 25%;
	padding: 0.2rem;
	height: 3rem;
}
.column-a {
	flex: 25%;
	padding: 0.2rem;
	height: 3.9rem;
}

.font-details-b {
	font-size: 3em;
	font-weight: 500;
	color: #fff;
}

.space {
	margin-top: 1rem !important;
}
@media only screen and (max-width: 600px) {
	.font-details-b {
		font-size: 2rem;
	}
	.card-title {
		font-size: 1.8rem;
	}
}
@media only screen and (min-width: 768px) {
	.col-md-4 {
		max-width: 98%;
	}
}
