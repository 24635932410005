.custom-img {
	height: 100vh;
	object-fit: cover;
}
// .photo {
// 	text-decoration: none;

// 	position: absolute;
// 	bottom: 8px;
// 	left: 16px;
// 	font-size: 18px;

// 	.img {
// 		font-size: small;
// 		color: gray;
// 	}
// }
/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {
// 	.custom-img {
// 		height: 120vh;
// 		object-fit: cover;
// 	}
// }

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
// 	.custom-img {
// 		height: 100vh;
// 		object-fit: cover;
// 	}
// }
