#projects {
	background-image: linear-gradient(135deg, #029fa5 0%, #02c0a0 100%);
	-webkit-clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
	clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
	padding: 6rem 0 10rem 0;
	margin-top: -1rem;
	margin-bottom: -1px;
	color: #fff;

	//date
	.cYsLMF.cYsLMF {
		background-color: rebeccapurple;
	}
	//bullet
	.ePyZdr.ePyZdr {
		border: 2px solid rebeccapurple;
	}
	//line
	.ftVSQk::after {
		background-color: rebeccapurple;
	}

	.accordian-main {
		cursor: pointer;
		display: inline-block;
		-webkit-text-decoration: none;
		text-decoration: none;
		font-size: 14px;
		background-color: rebeccapurple;
		border: none;
		border-radius: 4px;

		padding: 10px;
		color: #fff;
	}
	.container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	.image-style {
		width: 2rem;
	}

	.image-style-1 {
		width: 3rem;
	}
}
@media only screen and (max-width: 600px) {
	.font-details-b {
		font-size: 2rem;
	}
}
