.about {
	background-color: #029fa5;
	background-image: linear-gradient(135deg, #029fa5 0%, #02c0a0 100%);
	font-size: 1.3em;
	color: #fff;
	height: 100%;
	border-top: 0px;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
	clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
	padding-top: 4rem;
	padding-bottom: 10%;
}
.font-details-a {
	font-size: 3rem;
	font-weight: 500;
	color: #fff;
}

.profile {
	display: flex;
	width: 50%;
	height: 80%;
	align-items: center;
	justify-content: center;
}

.cta-btn {
	margin-top: 0.4rem;
	display: inline-block;
	position: relative;
	padding: 0.4rem;
	font-weight: bold;
	line-height: 1;
	z-index: 1;
	transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;

	&::after {
		content: "";
		display: block;
		position: absolute;
		width: 0px;
		height: 100%;
		left: 0;
		bottom: 0;
		z-index: -1;
		transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
	}
}

.cta-btn {
	color: #fff;
	border: 2px solid #fff;

	&::after {
		background: #fff;
	}

	&:hover {
		color: #02c0a0;
		text-decoration: none;

		&::after {
			width: 100%;
		}
	}
}
@media only screen and (max-width: 600px) {
	.font-details-a {
		font-size: 2rem;
	}
}
